<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >

    <div>

      <b-row>
        <b-col
          cols="6"
          md="3"
          sm="6"
          class="text-right"
        >
          <label class="mr-2">{{ $t("Total Amount") }}:</label>
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border"
            block
          >
            {{ totalAmount.toLocaleString() }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="3"
          sm="6"
          class="text-right"
        >
          <label class="mr-2">{{ $t("Total") + ' ' + $t("Discount") }}:</label>
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border"
            block
          >
            {{ totalDiscount.toLocaleString() }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="3"
          sm="6"
          class="text-right"
        >
          <label class="mr-2">{{ $t("Net") + ' ' + $t("Total") }}:</label>
          <b-button
            variant="flat-primary"
            class="mb-2 text-right border"
            block
          >
            {{ netTotal.toLocaleString() }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="3"
          sm="6"
          class="text-right"
        >
          <label class="mr-2">
            <feather-icon
              icon="FilterIcon"
              size="16"
            /></label>
          <b-button
            v-b-toggle.collapse-filter
            variant="flat-primary"
            block
            pill
            class="mb-2 text-right border"
          >
            {{ $t('Filters') }}
          </b-button>

        </b-col>
      </b-row>

      <b-collapse
        id="collapse-filter"
      >
        <!-- Filters -->
        <sale-list-filters
          :date-filter.sync="dateFilter"
          :date-filter-value.sync="dateFilterValue"
          :clear-date.sync="clearDate"
          :employee-filter.sync="employeeFilter"
          :employee-options="employeeOptions"
          :client-filter.sync="clientFilter"
          :client-options="clientOptions"
          :item-filter.sync="itemFilter"
          :item-options="itemOptions"
        />
      </b-collapse>

      <!-- Table Container Card -->
      <b-card
        class="mb-0"
        no-body
      >
        <b-card-header>
          <b-card-title>{{ $t('Sales') }}</b-card-title>
          <b-button
            v-if="$can('create', 'sale')"
            variant="primary"
            pill
            @click="add"
          >
            <span class="text-nowrap">{{ $t('New') }}</span>
          </b-button>
        </b-card-header>

        <b-card-body>
          <div class="mb-2 mt-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="5"
                md="6"
                class="d-flex align-items-center justify-content-start pl-0 mb-1 mb-md-0"
              >
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>

              <!-- Search -->
              <b-col
                cols="7"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search')"
                    @keyup.enter="search"
                  />
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
            ref="refSaleListTable"
            class="position-relative"
            :items="fetchSales"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >

            <!-- Column: Invoice -->
            <template #cell(client)="data">
              <b-link
                :to="{ name: 'pos-sale-view', params: { id: data.item.id } }"
                class=""
              >
                <div class="text-nowrap">
                  <p class="align-text-middle mb-0">
                    {{ data.item.id }} - {{ data.item.client.name }}
                  </p>
                  <label class="mb-0">Date: {{ data.item.sale_date }}</label><br>
                  <label class="mb-0">Net Total: {{ data.item.net_amount }}</label>
                </div>
              </b-link>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <b-link
                v-if="$can('update', 'sale')"
                :to="{ name: 'pos-sale-edit', params: { id: data.item.id } }"
                class="btn btn-light btn-icon rounded-pill mr-1"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                />
              </b-link>

              <b-link
                v-if="$can('delete', 'sale')"
                class="btn btn-light btn-icon rounded-pill"
                @click="del(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  class="text-danger"
                />
              </b-link>

            <!-- <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="$can('update', 'sale')"
                :to="{ name: 'pos-sale-view', params: { id: data.item.id } }"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">View</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('update', 'sale')"
                :to="{ name: 'pos-sale-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('delete', 'sale')"
              >
                <feather-icon icon="TrashIcon" />
                <span
                  class="align-middle ml-50"
                  @click="del(data.item.id)"
                >
                  Delete</span>
              </b-dropdown-item>
            </b-dropdown> -->

            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalSales"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card-body>
      </b-card>

    </div>

  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BCollapse, VBToggle,
  BPagination, BLink, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import vSelect from '@/libs/vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import SaleListFilters from './SaleListFilters.vue'
import storeModule from '../../../common/storeModule'
import useSaleList from './useSaleList'
import saleStoreModule from '../saleStoreModule'

export default {
  components: {
    SaleListFilters,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BCollapse,
    vSelect,
    BLink,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      branchOptions: [],
      employeeOptions: [],
      clientOptions: [],
      saleRegionOptions: [],
      tableColumns: [
        {
          key: 'id', label: this.$t('ID'), sortable: true, thClass: 'd-none', tdClass: 'd-none',
        },
        {
          key: 'client', label: this.$t('Invoice'), sortable: true, tdClass: 'align-middle',
        },
        {
          key: 'sale_date', label: this.$t('Date'), sortable: true, thClass: 'd-none', tdClass: 'd-none',
        },
        {
          key: 'net_amount', label: this.$t('Net'), sortable: true, thClass: 'd-none', tdClass: 'd-none',
        },
        {
          key: 'actions', label: this.$t('Actions'), thClass: 'align-middle', tdClass: 'align-middle',
        },
      ],
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'sale'

    // Register module
    if (!store.hasModule('common')) store.registerModule('common', storeModule)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, saleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const clearDate = null

    const {
      fetchSales,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleListTable,
      refetchData,
      totalAmount,
      totalDiscount,
      netTotal,

      // Filters
      employeeFilter,
      clientFilter,
      itemFilter,
      dateFilter,
      dateFilterValue,

      itemOptions,

    } = useSaleList()

    return {

      fetchSales,
      perPage,
      currentPage,
      totalSales,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSaleListTable,
      refetchData,
      totalAmount,
      totalDiscount,
      netTotal,
      clearDate,

      // Extra Filters
      employeeFilter,
      clientFilter,
      itemFilter,
      dateFilter,
      dateFilterValue,

      itemOptions,

    }
  },
  mounted() {
    this.loadEmployees()
    this.loadClients()
  },
  methods: {
    loadEmployees() {
      store
        .dispatch('common/fetchPosEmployees')
        .then(response => {
          const { employees } = response.data.data
          for (let i = 0; i < employees.length; i += 1) {
            this.employeeOptions.push({ label: employees[i].name, value: employees[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    loadClients() {
      store
        .dispatch('common/fetchPosClients')
        .then(response => {
          const { clients } = response.data.data
          for (let i = 0; i < clients.length; i += 1) {
            this.clientOptions.push({ label: clients[i].name, value: clients[i].id.toString() })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    search() {
      this.refetchData()
    },
    add() {
      this.$router.push({ name: 'pos-sale-new' })
    },
    del(id) {
      const that = this
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('sale/del', id)
            .then(response => {
              if (response.status === 200) {
                that.refetchData()
              } else {
                console.log(response)
              }
            })
            .catch(error => {
              window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
